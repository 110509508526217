@import "mixins";

/* ------------------------------------- Headers styles -------------------------------*/

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

/* Floats */
.float-left {
	float: left;
}

.float-right {
	float: right;
}

.float-none {
	float: none;
}

/* Absolute centering */

.relative {
	position: relative;
}

.absolute-center {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

/* Circle element */

.circle {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

body {
	font-family: $text;
}

/* Heading fonts */
h1, h2, h3, h4 ,h5, h6 {
	font-family: $title;
}

h1 {
	@include rem('font-size', 41px);
	text-transform: uppercase;
	font-weight: 700;
}

h2 {
	text-transform: uppercase;
	@include rem('font-size', 36px);
	font-weight: 700;
	color: #35393d;
	position: relative;
	padding-bottom: 38px;
	line-height: 42px;
	span {
		color: #c6be60;
	}
}

h3 {
	@include rem('font-size', 20px);
}

h4 {
	@include rem('font-size', 16px);
}

a {
	text-decoration: none;
}

.btn {
	width: 300px;
	height: 54px;
	display: inline-block;
	background: url(../images/btn-bg.png) no-repeat;
	background-size: cover;
	color: white;
	text-align: center;
	@include rem('font-size', 16px);
	font-weight: 700;
	font-family: $title;
	text-transform: uppercase;
	border: none;
	transition: .4s;
	&:hover {
		filter: brightness(125%);
		transition: .4s;
	}
}

/* Change font size big text */
.big-font {
	font-size: 28px;
}

/* Change font size small text */
.small-font {
	font-size: 14px;
}

header {
	height: 765px;
	.video-container {
		position: absolute;
		display: block;
		width: 100%;
		height: 765px;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		overflow: hidden;
	}
	video {
		position: absolute;
		min-width: 100%;
		min-height: 100%;
		width: auto; height: auto;
		top: 0;
		left: 0;
	}
	&:before {
		content: '';
		background: url('../images/bottom-video.png') 50% no-repeat;
		position: absolute;
		width: 100%;
		height: 24px;
		bottom: 0;
		left: 0;
		z-index: 5;
	}
	&:after {
		content: "";
		background: url(../images/patternt.png);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: .8;
	}
	.container_12 {
		position: relative;
		z-index: 10;
	}
	.logo {
		padding-right: 30px;
		margin-top: 26px;
	}
	.phones {
		text-align: right;
		margin-top: 22px;
	}
	.contact-phone {
		font-family: $text;
		line-height: 22px;
		color: #FFF;
		img {
			margin-right: 10px;
		}
		&:hover {
			color: $hover-color;
		}
	}
	.btn-header {
		background: transparent;
		border: none;
		color: white;
		font-family: $text;
		text-transform: uppercase;
		@include rem('font-size', 12px);
		letter-spacing: 0.05em;
		position: relative;
		display: inline-block;
		padding: 10px 0 0 0;
		&:before {
			content: "";
			position: absolute;
			bottom: -3px;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #FFF;
			transition: .4s;
		}
		&:hover {
			color: $hover-color;
			&:before {
				background: $hover-color;
				transition: .4s;
			}
		}
	}
	.arrow-right, .arrow-left {
		width: 46px;
	}
	.arrow-left {
		transform: rotate(180deg);
		position: absolute;
		top: 50%;
		left: 5px;
		margin-top: -6px;
	}
	.arrow-right {
		position: absolute;
		top: 50%;
		right: 5px;
		margin-top: -6px;
	}
	h4 {
		margin-top: 42px;
		color: white;
		line-height: 22px;
		margin-bottom: 90px;
	}
	h3 {
		color: white;
		line-height: 26px;
		span {
			font-weight: 700;
		}
	}
	h1 {
		margin: 45px 0 42px 0;
		line-height: 50px;
		position: relative;
		color: #fffffe;
	}
	.btn-header-link {
		position: relative;
		margin: 28px auto 110px auto;
		span {
			position: absolute;
			display: inline-block;
			margin: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 18px;
		}
	}
	.icon-next {
		display: block !important;
		margin-bottom: 60px;
	}
}

@media screen and (min-width: 761px) and (max-width: $descope-width + 20px) {
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
	header .contact-phone img {
		display: none;
	}
	header .contact-phone {
		display: block;
		@include rem('font-size', 14px);
	}
}

@media screen and (max-width: 760px) {
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
	header:before {
		content: none;
	}
	header {
		height: auto;
		padding-bottom: 40px;
		.video-container {
			height: auto;
		}
		.container_12 {
			.logo {
				display: none;
			}
		}
		h4 {
			margin: 0;
			padding: 50px 120px 30px 120px;
			@include rem('font-size', 21px);
			font-weight: 400;
			line-height: 22px;
		}
		.phones {
			text-align: center;
			margin-top: 0;
		}
		.contact-phone {
			display: block;
			@include rem('font-size', 18px);
			img {
				display: none;
			}
		}
		.btn-header {
			display: inline-block;
			color: #b0a958;
			@include rem('font-size', 26px);
			font-weight: 700;
			color: #b0a958;
			margin-bottom: 25px;
			&:before {
				content: "";
				background: #b0a958;
			}
		}
		.btn-header-link {
			margin-bottom: 70px;
		}
		h3 {
			@include rem('font-size', 24px);
		}
		h1 {
			@include rem('font-size', 34px);
			line-height: 38px;
		}
		.arrow-left {
			display: none;
		}
		.arrow-right {
			display: none;
		}
		.icon-next {
			display: none !important;
		}
	}
}


@media screen and (max-width: 420px) {
	header:before {
		content: none;
	}
	header {
		h4 {
			padding: 10px 0 10px 0;
			@include rem('font-size', 18px);
		}
		h3 {
			@include rem('font-size', 20px);
			line-height: 22px;
		}
		h1 {
			margin: 20px 0;
			@include rem('font-size', 24px);
			line-height: 26px;
		}
	}
}
