/* ---------------------------------------------------- input base styles --------------------------------------*/

a,
input[type="submit"],
input[type="button"],
button{
	-webkit-transition: color .3s ease-in, border-color .3s ease-in, background-color .3s ease-in, opacity .3s ease-in;
	-moz-transition: color .3s ease-in, border-color .3s ease-in, background-color .3s ease-in, opacity .3s ease-in;
	transition: color .3s ease-in, border-color .3s ease-in, background-color .3s ease-in, opacity .3s ease-in;
	outline: none;
}

input[type="submit"],
input[type="button"],
button {
	display: inline-block;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
textarea,
select {
	-webkit-transition: color .3s ease-in, border-color .3s ease-in, background-color .3s ease-in, opacity .3s ease-in;
	-moz-transition: color .3s ease-in, border-color .3s ease-in, background-color .3s ease-in, opacity .3s ease-in;
	transition: color .3s ease-in, border-color .3s ease-in, background-color .3s ease-in, opacity .3s ease-in;
	outline: none;
}

button {
	cursor: pointer;
}

a:hover, input:hover, textarea:hover, i:hover {
	-webkit-transition: color .3s ease-in, border-color .3s ease-in, background-color .3s ease-in, opacity .3s ease-in;
	-moz-transition: color .3s ease-in, border-color .3s ease-in, background-color .3s ease-in, opacity .3s ease-in;
	transition: color .3s ease-in, border-color .3s ease-in, background-color .3s ease-in, opacity .3s ease-in;
}

a {
	text-decoration: none;
	background-color: transparent;
}

a:active,
a:hover {
	outline: 0;
}

input::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
input::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
input:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
input:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

textarea::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
textarea::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
textarea:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
textarea:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
textarea:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

:focus::-webkit-input-placeholder {color: transparent;}
:focus::-moz-placeholder          {color: transparent;}
:focus:-moz-placeholder           {color: transparent;}
:focus:-ms-input-placeholder      {color: transparent;}

#thank-button {
	display: none;
}

/* Hidden in medium sreen */

.hidden-mobile {
	display: block !important;
}

.vissible-mobile {
	display: none !important;
}

/* No margin in block */
.no-margin {
	margin-left: 0;
	margin-right: 0;
}

/*----------------------------- Layout -------------------------- */

/* Text align */
.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

/* Floats */
.float-left {
	float: left;
}

.float-right {
	float: right;
}

.float-none {
	float: none;
}

/* Absolute centering */

.relative {
	position: relative;
}

.absolute-center {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

/* Circle element */

.circle {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

/* ---------------------- Modal window bootstrap -------------------------- */

.modal {
	text-align: center;
	&:after {
		display: inline-block;
		vertical-align: middle;
		width: 0;
		height: 100%;
		content: '';
	}
}

.modal-window {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	box-sizing: border-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	background-clip: padding-box;
	outline: 0;
}

.modal-content {
	background-clip: padding-box;
	outline: 0;
	.field {
		position: relative;
	}
	i {
		position: absolute;
	}
	.form_input {
		display: block;
		text-align: center;
	}
}

.modal-window .form_input .error {
	margin-bottom: 5px;
}

button.close {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
}

.modal-open {
	overflow: hidden;
}

.modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	-webkit-overflow-scrolling: touch;
	outline: 0;
	font-size: 0;
}

.modal.fade .modal-dialog {
	-webkit-transform: translate(0, -25%);
	-ms-transform: translate(0, -25%);
	transform: translate(0, -25%);
	-webkit-transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 10px;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000;
}

.modal-backdrop.fade {
	opacity: 0;
	filter: alpha(opacity=0);
}

.modal-backdrop.in {
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.modal-header {
	border-bottom: 1px solid #e5e5e5;
	position: relative;
}

.modal-header:before,
.modal-header:after {
	content: " ";
	display: table;
}

.modal-header:after {
	clear: both;
}

.modal-header .close {
	margin-top: -2px;
}

.fade {
	opacity: 0;
	-webkit-transition: opacity 0.15s linear;
	transition: opacity 0.15s linear;
}

.fade.in {
	opacity: 1;
}

.modal.fade .modal-dialog {
	-webkit-transform: translate(0, -25%);
	-ms-transform: translate(0, -25%);
	transform: translate(0, -25%);
	-webkit-transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.modal-backdrop.fade {
	opacity: .6;
	filter: alpha(opacity=0);
}

.modal-title {
	margin: 0;
	line-height: 1.42857;
}

.modal-body {
	position: relative;
	padding: 15px;
}

.modal-content .close,
.modal-header .close {
	display: inline;
	position: absolute;
	top: 0;
	right: 0;
	height: 30px;
	width: 30px;
}

.modal-footer {
	padding: 15px;
	text-align: right;
	border-top: 1px solid #e5e5e5;
}

.modal-footer:before,
.modal-footer:after {
	content: " ";
	display: table;
}

.modal-footer:after {
	clear: both;
}

.modal-footer .btn + .btn {
	margin-left: 5px;
	margin-bottom: 0;
}

.modal-footer .btn-group .btn + .btn {
	margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
	margin-left: 0;
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}
