@import "reset";
@import "fonts";
@import "font-awesome";
@import "grid";
@import "variables";
@import "libs";
@import "base_styles";
@import "animate";
@import "headers";

/* Theme settings */

/* Global fonts */

/* Styles for placeholder */
*::-webkit-input-placeholder {
	color: #35393d;
	@include rem('font-size', 12px);
	font-family: $text;
}
*:-moz-placeholder {
	color: #35393d;
	@include rem('font-size', 12px);
	font-family: $text;
}
*::-moz-placeholder {
	color: #35393d;
	@include rem('font-size', 12px);
	font-family: $text;
}
*:-ms-input-placeholder {
	color: #35393d;
	@include rem('font-size', 12px);
	font-family: $text;
}

/* --------------------------------Custom styles--------------------------------------------------- */

body {

}

.slick-slide {
	outline: none;
}

/* Thank you message styles */

#thank p {
	@include rem('font-size', 18px);
	color: #000;
}

/* Allert styles */

.allert {
	text-align: center;
	span {
		display: inline-block;
		color: #FFF;
		border-radius: 5px;
		background: $hover-color;
		padding: 5px 10px;
		margin: 0 0 5px 0;
		font-size: 16px;
		position: relative;
	}
	span:after {
		bottom: 100%;
		left: 80%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(0, 0, 0, 0);
		border-bottom-color: $hover-color;
		border-width: 5px;
		margin-left: -5px;
	}
}

.icon-title {
	width: 41px;
	height: 14px;
	display: inline-block;
}

.title-line {
	width: 41px;
	height: 14px;
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -20.5px;
}

.gray-color {
	fill: #e6e6e6;
}

.white-color {
	fill: #FFFFFF;
}

.black-color {
	fill: #000000;
}

.waiting {
	padding: 94px 0 92px 0;
	h2 {
		margin-bottom: 39px;
	}
	.item {
		padding-top: 30px;
		svg {
			transition: all 0.5s cubic-bezier(0.01, 0.54, 0.76, 1.21);
		}
		&:hover {
			svg {
				transform: scale(1.2);
				transition: all 0.5s cubic-bezier(0.01, 0.54, 0.76, 1.21);
			}
		}
	}
	.first {
		svg {
			width: 60px;
			height: 60px;
			margin-bottom: 52px;
		}
	}
	.second {
		svg {
			width: 64px;
			height: 60px;
			margin-bottom: 52px;
		}
	}
	.three {
		svg {
			width: 42px;
			height: 60px;
			margin-bottom: 52px;
		}
	}
	.four {
		svg {
			width: 51px;
			height: 60px;
			margin-bottom: 52px;
		}
	}
	.five {
		svg {
			width: 60px;
			height: 60px;
			margin-bottom: 52px;
		}
	}
	.six {
		svg {
			width: 60px;
			height: 60px;
			margin-bottom: 52px;
		}
	}
	.clear {
		margin-bottom: 45px;
	}
	.icon-bg {
		position: absolute;
		width: 120px;
		left: 50%;
		top: 0;
		margin-left: -60px;
	}
	p {
		color: #35393d;
		line-height: 22px;
	}
}

.guest {
	background: url(../images/guest.jpg) 50% no-repeat;
	background-size: cover;
	position: relative;
	padding: 158px 0 224px 0;
	.section-image {
		position: absolute;
	}
	.second-extrasmall, .second-small {
		display: none;
	}
	.first {
		top: -75px;
		left: -15%;
		transition: .4s;
	}
	.second {
		top: -215px;
		right: 0;
	}
	.third {
		bottom: -120px;
		left: -150px;
	}
	.container_12 {
		position: relative;
		z-index: 20;
	}
	h2 {
		color: #FFF;
		margin-bottom: 5px;
	}
	.section-desk {
		@include rem('font-size', 18px);
		line-height: 24px;
		margin-bottom: 90px;
		color: white;
	}
	.tour-section {
		height: 519px;
	}
	.visual-tour {
		text-align: right;
		float: right;
		text-transform: uppercase;
		color: #FFF;
		@include rem('font-size', 11px);
		margin-bottom: 8px;
		img {
			margin-right: 10px;
		}
	}
	.tour-image {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		cursor: pointer;
		box-shadow: 0 29px 43px rgba(0, 0, 0, 0.69);
	}
	iframe {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 488px;
		box-shadow: 0 29px 43px rgba(0, 0, 0, 0.69);
	}
}

.datepicker {
	background: #EBEBEC;
}

.datepicker--pointer {
	background: #EBEBEC;
}

.datepicker--cell.-selected-.-current-,
.datepicker--cell.-selected- {
	background: #c6be60;
}

.datepicker {
	font-family: $text !important;
}

.tradition {
	padding: 65px 0 95px 0;
	h2 {
		margin-bottom: 25px;
	}
	.desk-wrapped {
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			transition: all 0.5s cubic-bezier(0.01, 0.54, 0.76, 1.21);
		}
		&:hover {
			svg {
				transform: scale(1.2);
				transition: all 0.5s cubic-bezier(0.01, 0.54, 0.76, 1.21);
			}
		}
		.first {
			width: 66px;
			height: 65px;
		}
		.second {
			width: 69px;
			height: 59px;
		}
		.three {
			width: 76px;
			height: 50px;
		}
		.icon-bg {
			position: absolute;
			width: 120px;
			height: 120px;
			top: 50%;
			left: 50%;
			margin-top: -60px;
			margin-left: -60px;
		}
		p {
			color: #35393d;
			line-height: 22px;
			margin-bottom: 22px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.clear {
		margin-bottom: 50px;
	}
	.desk-right {
		flex-direction: row-reverse;
		p {
			text-align: left;
		}
	}
	.form-title {
		@include rem('font-size', 22px);
		line-height: 24px;
		font-family: $title;
		color: #212121;
		margin: 75px 0 50px 0;
		span {
			color: #c6be60;
			font-weight: 700;
		}
	}
	form {
		.float-left {
			.field {
				margin-right: 5px;
			}
		}
		.float-right {
			.field {
				margin-left: 5px;
			}
		}
		.form_input {
			width: 50%;
			.field {
				width: 220px;
				display: inline-block;
				position: relative;
				margin-bottom: 5px;
				i {
					position: absolute;
					left: 10px;
					top: 18px;
					font-size: 18px;
				}
				.fa-mobile {
					left: 13px;
				}
				input {
					background-color: #ebebec;
					display: block;
					box-sizing: border-box;
					width: 100%;
					border: none;
					padding: 20px 20px 20px 35px;
				}
			}
		}
		.btn-block {
			margin-top: 20px;
		}
	}
}

.your-wait {
	padding: 195px 0 150px 0;
	background: url(../images/wait-bg.jpg) 50% no-repeat;
	background-size: cover;
	h2 {
		color: #FFF;
	}
	ol {
		counter-reset: list 0;
		margin-top: 80px;
		li {
			position: relative;
			padding-left: 84px;
			line-height: 22px;
			@include rem('font-size', 18px);
			font-weight: 400;
			color: #fff;
			margin-bottom: 45px;
			span {
				color: #c6be60;
				font-weight: 700;
			}
			&:before {
				counter-increment: list;
				content: counter(list) "";
				position: relative;
				color: white;
				font-size: 24px;
				position: absolute;
				margin: auto;
				top: 0;
				bottom: 0;
				left: 33px;
			}
			&:after {
				content: "";
				background: url(../images/list-number.png) no-repeat;
				width: 40px;
				height: 40px;
				position: absolute;
				margin: auto;
				top: 0;
				bottom: 0;
				left: 20px;
				transition: .8s;
			}
			&:hover {
				&:after {
					transform: rotate(270deg);
				}
			}
		}
		li:first-child {
			&:before {
				top: 12px;
			}
		}
		li:nth-child(2) {
			&:before {
				top: 22px;
			}
		}
		li:nth-child(3) {
			&:before {
				top: 22px;
			}
		}
		li:nth-child(4) {
			&:before {
				top: 22px;
			}
		}
		li:nth-child(5) {
			&:before {
				top: 22px;
			}
		}
		li:last-child {
			&:before {
				top: 22px;
			}
		}
	}
}

.gallery {
	padding: 115px 0 80px 0;
	h2 {
		margin-bottom: 0px;
	}
	.slide {
		outline: none;
		img {
			width: 100%;
		}
	}
	#big-slider {
		.image-wrapped {
			position: relative;
			padding: 35px;
			&:before {
				content: "";
				position: absolute;
				height: 100%;
				width: 100%;
				background: url(../images/slider-reck.png) no-repeat;
				margin: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}
		p {
			color: #c6be60;
			font-family: $title;
			font-weight: 700;
			text-transform: uppercase;
			@include rem('font-size', 36px);
			margin: 15px 0 32px 0;
		}
	}
	#slider-nav {
		.slick-list {
			height: 235px;
		}
		.slide {
			box-sizing: border-box;
			padding: 0 10px;
			cursor: pointer;
			transition: .4s;
			position: relative;
			&:before {
				content: "";
				width: 0;
				transition: .4s;
			}
			&:after {
				content: "";
				width: 0;
				transition: .4s;
			}
			p {
				color: #35393d;
				font-family: $text;
				@include rem('font-size', 18px);
				margin: 10px 0;
			}
		}
		.hover-slide-right {
			&:before {
				content: "";
				box-shadow: inset -83px 0px 112px -41px rgba(255,255,255,.5);
				width: 80px;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
		.hover-slide-left {
			&:after {
				content: "";
				box-shadow: inset 83px 0px 112px -41px rgba(255,255,255, .5);
				width: 80px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		.slick-prev:before, .slick-next:before {
			content: none;
		}
		.slick-next {
			width: 29px;
			height: 29px;
			margin: auto;
			right: 20px;
			top: -30px;
			bottom: 0;
			background: url(../images/restart.svg) no-repeat;
			transform: rotate(180deg);
			z-index: 99;
			transition: .4s;
		}
		.slick-prev {
			width: 29px;
			height: 29px;
			margin: auto;
			left: 20px;
			top: 0;
			bottom: 0;
			background: url(../images/restart.svg) no-repeat;
			z-index: 99;
			transition: .4s;
		}
		.slick-prev:hover, .slick-next:hover {
			opacity: .6;
		}
		.slick-dots li button {
			padding: 0;
			display: inline-block;
			width: 15px;
			height: 15px;
			margin-top: 10px;
			background-color: #ebebec;
			border-radius: 50%;
			transition: .4s;
			&:hover {
				background-color: #c6be60;
			}
		}
		.slick-dots .slick-active button {
			background-color: #c6be60;
		}
		.slick-dots li button:before {
			content: none;
		}
	}
}

.menu {
	background: url(../images/menu-bg.jpg) 50% no-repeat;
	background-size: cover;
	padding: 120px 0 95px 0;
	h2 {
		margin-bottom: 30px;
	}
	#slider-menu {
		display: block;
		li {
			display: inline-block;
			float: left;
			color: #35393d;
			margin: 0 5px;
			text-align: center;
			cursor: pointer;
			padding: 10px 5px;
			box-sizing: border-box;
		}
		.slick-current {
			border: 1px solid #35393d;
			border-radius: 5px;
			font-weight: 700;
		}
	}
	#slider-food {
		text-align: center;
		margin-top: 68px;
		.menu-item {
			display: inline-block;
			width: 800px;
			box-sizing: border-box;
			background: #FFF;
			padding: 54px 0 30px 0;
			box-shadow: 0 21px 21px rgba(0, 0, 0, 0.2);
			margin-bottom: 20px;
		}
		p {
			text-align: left;
		}
		.price, .mass {
			padding: 0 70px 0 80px;
		}
		.price {
			color: #35393d;
			.value {
				float: right;
			}
			.doten {
				letter-spacing: 0.35em;
			}
			.price-short {
				margin-right: 9px;
			}
		}
		.mass {
			@include rem('font-size', 12px);
			font-weight: lighter;
			margin-bottom: 48px;
		}
		.recomend {
			border: 1px solid #c6be60;
			border-radius: 5px;
			margin: -20px 60px 35px 70px;
			padding: 8px 8px 8px 8px;
			.recomend-text {
				color: #c6be60;
				font-weight: lighter;
				margin-bottom: 8px;
			}
			.price, .mass {
				padding: 0;
			}
			.mass {
				margin-bottom: 0;
			}
		}
	}
	.nav-prev {
		position: absolute;
		top: 265px;
		left: 0px;
		border: 1px solid #35393d;
		svg {
			transform: rotate(180deg);
			width: 43px;
			height: 16px;
			position: absolute;
			margin: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.nav-next {
		position: absolute;
		top: 265px;
		right: 0;
		svg {
			width: 43px;
			height: 16px;
			position: absolute;
			margin: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.nav-prev, .nav-next {
		border: 1px solid transparent;
		width: 56px;
		height: 56px;
		border-radius: 50%;
		cursor: pointer;
		transition: .4s;
		&:hover {
			border: 1px solid #35393d;
		}
	}
}

.now {
	padding: 110px 0 75px 0;
	border-bottom: 1px solid #ebebec;
	position: relative;
	.bottom-icon {
		position: absolute;
		width: 41px;
		height: 14px;
		left: 50%;
		bottom: -7px;
		margin-left: -32.5px;
		background: #FFF;
		padding: 0 12px;
	}
	h2 {
		margin-bottom: 85px;
	}
	a {
		color: #c6b760;
		@include rem('font-size', 18px);
		text-decoration: underline;
		text-transform: uppercase;
		font-weight: 700;
		display: inline-block;
		margin-top: 15px;
	}
	.item {
		padding-top: 30px;
		svg {
			transition: all 0.5s cubic-bezier(0.01, 0.54, 0.76, 1.21);
		}
		&:hover {
			svg {
				transform: scale(1.2);
				transition: all 0.5s cubic-bezier(0.01, 0.54, 0.76, 1.21);
			}
		}
	}
	svg {
		fill: #7b856f;
	}
	.first {
		svg {
			width: 86px;
			height: 74px;
			margin-bottom: 25px;
			position: relative;
			top: -5px;
		}
	}
	.second {
		svg {
			width: 72px;
			height: 70px;
			margin-bottom: 28px;
			position: relative;
			top: -8px;
		}
	}
	.three {
		svg {
			width: 88px;
			height: 68px;
			margin-bottom: 30px;
			position: relative;
			top: -8px;
		}
	}
	.icon-bg {
		position: absolute;
		width: 120px;
		left: 50%;
		top: 0;
		margin-left: -60px;
	}
	p {
		color: #35393d;
		line-height: 22px;
	}
}

.feedback {
	padding: 118px 0 70px 0;
	h2 {
		margin-bottom: 0;
	}
	#feedback-slider {
		.slick-list {
			padding: 40px 0 32px 0;
		}
		.feedback-desk {
			padding: 30px 35px 60px 35px;
			border: 1px solid #ebebec;
			position: relative;
			.icon-slider {
				width: 43px;
				height: 16px;
			}
			.icon-top-left {
				position: absolute;
				transform: rotate(-135deg);
				top: -8px;
				left: -23px;
			}
			.icon-top-right {
				position: absolute;
				transform: rotate(-45deg);
				top: -8px;
				right: -23px;
			}
			.icon-bottom-left {
				position: absolute;
				transform: rotate(135deg);
				bottom: -8px;
				left: -23px;
			}
			.icon-bottom-right {
				position: absolute;
				transform: rotate(45deg);
				bottom: -8px;
				right: -23px;
			}
		}
		.name {
			text-align: left;
			color: #35393d;
			font-weight: 700;
			margin-bottom: 25px;
		}
		.feedback-text {
			text-align: left;
			color: #35393d;
			font-weight: 400;
		}
		.read_more {
			color: #c6ba60;
			font-weight: 400;
			text-decoration: underline;
			background: transparent;
			border: none;
			@include rem('font-size', 18px);
			margin-left: 5px;
		}
	}
	.icon-prev, .icon-next {
		cursor: pointer;
		svg {
			width: 43px;
			height: 16px;
			fill: #dededf;
			transition: .4s;
		}
		&:hover {
			svg {
				fill: #c6be60;
			}
		}
	}
	.icon-prev {
		position: absolute;
		left: 0;
		top: 132px;
		svg {
			transform: rotate(180deg);
		}
	}
	.icon-next {
		position: absolute;
		right: 0;
		top: 132px;
	}
	.slick-dots li button:before {
		content: none;
	}
	.slick-dots li button {
		width: 15px;
		height: 15px;
		padding: 0;
		background-color: #dededf;
		border-radius: 50%;
		transition: .4s;
		&:hover {
			background-color: #c6be60;
		}
	}
	.slick-dots .slick-active button {
		background-color: #c6be60;
	}
}

.present {
	padding: 140px 0 100px 0;
	background: url(../images/present-bg.jpg) 50% no-repeat;
	background-size: cover;
	.section-desk {
		color: white;
		font-weight: 400;
		font-family: $title;
		@include rem('font-size', 22px);
		line-height: 24px;
		margin-bottom: 90px;
		span {
			color: #c6be60;
			font-weight: 700;
		}
	}
	form {
		.float-left {
			.field {
				margin-right: 5px;
			}
		}
		.float-right {
			.field {
				margin-left: 5px;
			}
		}
		.form_input {
			width: 50%;
			.field {
				width: 220px;
				display: inline-block;
				position: relative;
				margin-bottom: 5px;
				i {
					position: absolute;
					left: 10px;
					top: 18px;
					font-size: 18px;
				}
				.fa-mobile {
					left: 13px;
				}
				input {
					background-color: #ebebec;
					display: block;
					box-sizing: border-box;
					width: 100%;
					border: none;
					padding: 20px 20px 20px 35px;
				}
			}
		}
		.btn-block {
			margin-top: 20px;
		}
	}
}
/* Footer */

.footer {
	padding: 28px 0 40px 0;
	.phones {
		text-align: right;
		margin-top: 24px;
	}
	.contact-phone {
		font-family: $text;
		line-height: 22px;
		color: #35393d;
		transition: .4s;
		&:hover {
			color: $hover-color;
		}
		img {
			margin-right: 10px;
		}
	}
	.btn-header {
		background: transparent;
		border: none;
		color: #212121;
		font-family: $text;
		text-transform: uppercase;
		@include rem('font-size', 12px);
		letter-spacing: 0.05em;
		position: relative;
		display: inline-block;
		padding: 10px 0 0 0;
		&:before {
			content: "";
			position: absolute;
			bottom: -3px;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #000;
			transition: .4s;
		}
		&:hover {
			color: $hover-color;
			&:before {
				background: $hover-color;
				transition: .4s;
			}
		}
	}
	p {
		padding-top: 46px;
		color: #35393d;
	}
	.social-button {
		.social-item {
			margin-left: 10px;
			margin-right: 10px;
			margin-top: 20px;
			display: inline-block;
			i {
				font-size: 46px;
				transition: .4s;
			}
			.fa-facebook-square {
				color: #475993;
			}
			.fa-instagram {
				color: #35393D;
			}
			&:hover {
				opacity: .6;
			}
		}
	}
}

#google-map {
	height: 500px;
	position: relative;
	opacity: .6;
	&:before {
		content: "";
		position: absolute;
		z-index: 10;
		width: 20%;
		height: 100%;
		left: 0;
		top: 0;
		box-shadow: inset 190px 0px 200px -20px rgba(255,255,255,0.71);
	}
	&:after {
		content: "";
		position: absolute;
		z-index: 10;
		width: 20%;
		height: 100%;
		right: 0;
		top: 0;
		box-shadow: inset -190px 0px 200px 20px rgba(255,255,255,0.71);
	}
}

/* -------------------------- Company footer --------------------------------- */

.company-footer {
	/* Company Footer bg */
	background: url(../images/politics-bg.jpg) 50% no-repeat;
	background-size: cover;
	padding: 25px 0;
	.company-wrapped {
		display: block;
		.politics {
			padding-left: 10px;
			padding-top: 8px;
			color: #FFF;
			font-size: 14px;
			display: inline-block;
			font-family: $text;
			@include rem('font-size', 15px);
		}
		.politics:hover {
			color: #c6b563;
		}
	}
	.company-logo {
		float: right;
		color: #eaeaea;
		font-family: $text;
		@include rem('font-size', 15px);
		margin-top: 8px;
		a {
			color: #c6b563;
			transition: .4s;
			&:hover {
				color: #FFF;
			}
		}
	}

}

/* Modal window custom styles */

/* modal styles and sizes */

.modal-window {
	width: 436px;
	background: transparent;
	padding: 10px;
	border: 1px solid #ebebec;
	border-radius: 0;
	box-shadow: none;
	.modal-content {
		padding: 0 50px;
		padding-bottom: 50px;
	}
	h3 {
		font-weight: 900;
		font-family: $title;
		@include rem('font-size', 36px);
		color: #35393d;
		padding: 30px 0 60px 0;
	}
	p {
		@include rem('font-size', 18px);
		color: #35393d;
		font-family: $title;
		line-height: 17px;
		font-weight: 400;
		margin-bottom: 24px;
	}
	.modal-content {
		background: #FFF;
	}
	.icon-slider {
		width: 43px;
		height: 16px;
	}
	.icon-top-left {
		position: absolute;
		transform: rotate(-135deg);
		top: -8px;
		left: -23px;
	}
	.icon-top-right {
		position: absolute;
		transform: rotate(-45deg);
		top: -8px;
		right: -23px;
	}
	.icon-bottom-left {
		position: absolute;
		transform: rotate(135deg);
		bottom: -8px;
		left: -23px;
	}
	.icon-bottom-right {
		position: absolute;
		transform: rotate(45deg);
		bottom: -8px;
		right: -23px;
	}
}

.modal-content {
	margin-top: 0;
	padding-bottom: 50px;
	input {
		background-color: #ebebec;
		display: block;
		box-sizing: border-box;
		width: 100%;
		border: none;
		padding: 20px 20px 20px 35px;
		margin-top: 10px;
	}
	i {
		font-size: 18px;
		position: absolute;
		color: #000;
		left: 10px;
		top: 18px;
	}
	.fa-mobile {
		left: 14px;
	}
	.form_input {
		position: relative;
	}
	.privacy {
		font-size: 12px;
		padding: 8px 0;
	}

	/* Form button */

	.btn {
		width: 100%;
		padding: 10px;
		margin-top: 10px;
	}

	.btn:hover {

	}

	/* Inputs placeholder */

	*::-webkit-input-placeholder {
		color: #35393d;
		@include rem('font-size', 12px);
		font-family: $text;
	}
	*:-moz-placeholder {
		color: #35393d;
		@include rem('font-size', 12px);
		font-family: $text;
	}
	*::-moz-placeholder {
		color: #35393d;
		@include rem('font-size', 12px);
		font-family: $text;
	}
	*:-ms-input-placeholder {
		color: #35393d;
		@include rem('font-size', 12px);
		font-family: $text;
	}

}

.modal-content .field {
	position: static;
}

/* Modal politics */

.modal-politics {
	height: auto;
	width: 75%;
	max-width: none;
	.modal-title {
		@include rem('font-size', 26px);
		padding: 0 50px;
		text-align: center;
	}
	.modal-content {
		position: relative;
		margin-top: 0;
		/* Paragraph style in politics */
		p {
			font-size: 14px;
			color: #000;
			text-align: left;
		}
	}
}

.modal-politics .modal-content .modal-title {
	@include rem('font-size', 22px);
	text-align: center;
	padding: 22px 0;
	font-weight: 700;
	text-transform: uppercase;
}

#thank {
	.modal-window .modal-content {
		padding: 40px;
		p {
			margin-bottom: 0;
		}
	}
}

@import "media";
