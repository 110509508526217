/* ------------------------------------------ Media Queries ----------------------------------- */

@media screen and (min-width: 981px) and (max-width: 1110px) {
	.guest .first {
		left: -36%;
	}
}

@media screen and (min-width: 1180px) and (max-width: 1340px) {
	.guest .first {
		left: -20%;
	}
}

@media screen and (min-width: 1111px) and (max-width: 1340px) {
	.guest .first {
		left: -25%;
	}
}

@media screen and (min-width: 1075px) and (max-width: 1400px) {
	.guest .second-big, .guest .second-small {
		display: none;
	}
	.guest .second-small {
		display: block;
	}
}

@media screen and (min-width: 981px) and (max-width: 1074px) {
	.guest .second-big,  {
		display: none;
	}
	.guest .second-extrasmall {
		display: block;
	}
}

@media screen and (min-width: 761px) and (max-width: $descope-width + 20px) {
	/* Tablets styles */
	.hidden-mobile {
		display: block !important;
	}

	.btn {
		&:hover {
			filter: none;
		}
	}

	.vissible-mobile {
		display: none !important;
	}

	img {
		max-width: 100%;
	}

	header:after {
		//content: none;
	}

	.waiting {
		position: relative;
		&:before {
			content: "";
			width: 100%;
			height: 40px;
			position: absolute;
			top: -10px;
			left: 0;
			background: #FFF;
			z-index: 10;
		}
	}

	.guest {
		.section-image {
			display: none;
		}
	}

	.your-wait {
		.container_12 .grid_7 {
			width: 85%;
		}
	}

	.footer .contact-phone img {
		display: none;
	}

	.footer .contact-phone {
		@include rem('font-size', 14px);
	}

	.footer {
		.social-button {
			margin-left: 0;
			width: 25%;
			margin-top: 20px;
		}
	}

	#google-map:before, #google-map:after {
		content: none;
	}

	/* Modal window */

	.modal-window {
		.modal-content {
			.btn {
				padding: 10px;
				width: 100%;
			}
		}
	}
}

/* --------------------------------------- */

@media screen and (max-width: 760px) {
	/* Mobile styles */
	/* Vissible block */
	.hidden-mobile {
		display: none !important;
	}

	.btn {
		&:hover {
			filter: none;
		}
	}

	.vissible-mobile {
		display: block !important;
	}

	/* Image sizes */

	img {
		max-width: 100%;
	}

	/* Vissible brs */

	br {
		display: none;
	}

	h1 {

	}

	h2 {
		@include rem('font-size', 24px);
		line-height: 26px;
		padding-bottom: 22px;
	}

	h3 {

	}

	h4 {

	}

	h5 {

	}

	h6 {

	}

	section {
		padding: 40px 0 !important;
	}

	/* ---------------------------- Custom styles --------------------------------------*/

	.waiting p {
		padding-bottom: 20px;
	}

	.guest .section-image {
		display: none;
	}

	.guest {
		padding: 80px 0 40px 0 !important;
	}

	header:after {
		//content: none;
	}

	.guest {
		.tour-section {
			text-align: center;
		}
	}

	.guest .tour-section {
		height: 340px;
	}

	.guest .visual-tour {
		float: none;
		text-align: center;
	}

	.guest .tour-image {
		bottom: auto;
		top: 0;
	}

	.guest .visual-tour {
		display: none;
	}

	.guest iframe {
		height: 340px !important;
		bottom: auto;
		top: 0;
	}

	.tradition .desk-wrapped {
		display: block;
	}

	.tradition {
		.icon-item {
			margin: 40px 0 20px 0;
		}
		.desk-wrapped {
			.text-right {
				text-align: center;
			}
		}
		.desk-right p {
			text-align: center;
		}
	}

	.tradition form .form_input {
		width: 100%;
	}

	.tradition {
		.float-left, .float-right {
			float: none;
			text-align: center;
		}
		.text-right, .text-left {
			text-align: center;
		}
	}

	.tradition form .float-left .field {
		margin-right: 0;
	}

	.tradition form .float-right .field {
		margin-left: 0;
	}

	.tradition form .btn-block {
		width: 220px;
	}

	.your-wait {
		padding: 140px 0 40px 0 !important;
	}

	.your-wait ol li:before,
	.your-wait ol li:after {
		content: none;
	}

	.your-wait ol li {
		padding-left: 0;
		text-align: center;
	}

	.gallery #big-slider .image-wrapped:before {
		content: none;
	}

	.gallery #big-slider .image-wrapped {
		padding: 0;
	}

	.gallery #slider-nav .slick-next,
	.gallery #slider-nav .slick-prev {
		display: none !important;
	}

	.gallery #slider-nav .slick-list {
		height: auto;
	}

	.gallery #slider-nav .slide p {
		@include rem('font-size', 12px);
	}

	.menu #slider-menu li {
		float: none;
		display: block;
		text-align: center;
		width: 100% !important;
		box-sizing: border-box;
		margin: 5px 0;
	}

	.menu #slider-food {
		margin-top: 20px;
	}

	.menu #slider-food .menu-item {
		width: 100%;
		padding: 30px 0;
	}

	.menu #slider-food .price, .menu #slider-food .mass {
		padding: 0 10px;
	}

	.menu #slider-food .price .doten {
		display: none;
	}

	.menu #slider-food .recomend {
		margin: -20px 0 25px 0;
		padding: 8px 8px;
	}

	.menu .nav-prev, .menu .nav-next {
		display: none !important;
	}

	.menu #slider-food .more-price {
		padding-right: 90px;
	}

	.menu #slider-food .menu-item {
		padding: 60px 10px;
	}

	.menu #slider-food .price {
		@include rem('font-size', 15px);
	}

	.now h2 {
		margin-bottom: 40px;
	}

	.now .item {
		margin-bottom: 30px;
	}

	.feedback-desk {
		margin-top: 20px;
		text-align: center;
		object {
			display: none;
		}
	}

	.slick-slide img {
		display: inline-block;
	}

	.feedback #feedback-slider .name, .feedback #feedback-slider .feedback-text {
		text-align: center;
	}

	.present .section-desk {
		margin-bottom: 35px;
	}

	.present form .form_input {
		width: 100%;
		float: none;
		text-align: center;
	}

	.present form .form_input .field {
		margin-right: 0;
	}

	.present form .form_input .field {
		margin-left: 0;
	}

	.present {
		.btn {
			width: 220px;
		}
	}

	.gallery #big-slider p {
		@include rem('font-size', 26px);
		text-align: center;
		margin-bottom: 10px;
	}

	#google-map {
		height: 250px;
	}

	/* Footer */

	.footer {
		.phones {
			text-align: center;
		}
		.contact-phone {
			display: block;
			text-align: center;
			@include rem('font-size', 18px);
			img {
				display: none;
			}
		}
		.btn-header {
			display: inline-block;
			color: #b0a958;
			@include rem('font-size', 26px);
			font-weight: 700;
			color: #b0a958;
			margin-bottom: 25px;
			&:before {
				background: #b0a958;
			}
		}
	}

	.footer p {
		padding-top: 10px;
	}

	.footer {
		.social-button {
			text-align: center;
		}
	}

	#google-map:before,
	#google-map:after {
		content: none;
	}

	/* ---------------------------- Modal window mobile ------------------------------- */

	.modal-window {
		max-width: 85%;
		h3{
			padding: 8px 25px;
		}
		input {
			//padding: 6px 15px;
		}
		.form-group {
			position: relative;
		}
		.btn {
			padding: 10px 0;
			width: 100%;
			margin-top: 5px;
		}
		.privacy {
			font-size: 14px;
		}
	}

	.modal-window h3 {
		@include rem('font-size', 24px);
		line-height: 20px;
	}

	.modal-window p {
		@include rem('font-size', 14px);
	}

	.modal-politics {
		width: 95%;
		max-width: 100%;
	}

	.modal-politics .modal-content .modal-title {
		@include rem('font-size', 18px);
		margin-bottom: 10px;
		padding: 0;
	}

	.modal-window .modal-content {
		padding: 10px;
		padding-bottom: 30px;
	}

	/* -------------------------------- Company footer -------------------------------- */

	.company-footer {
		.company-wrapped {
			text-align: center;
			.politics {
				display: block;
				padding-left: 0;
			}
			.company-logo {
				width: 100%;
				margin-top: 10px;
				float: none;
				display: inline-block;
			}
		}
	}
}
